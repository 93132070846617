const AUTH_URL = process.env.REACT_APP_AUTH_URL || 'https://vback.policiamisiones.gob.ar/api/v1'
const API_URL = process.env.REACT_APP_API_URL || ''

const DATA = {
  appSettings: {
    name: 'DGA - Dirección Verificación del Automotor',
    shortName: 'A. T. V. A',
    version: 'v2.0.3',
    poweredBy: 'Dirección Innovación, Desarrollo Tecnológico y Robótica',
    state: {
      SAVING_PERIOD: 1000,
    },
    regexps: {
      EMAIL: '[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]+',
      STRING:
        '^[A-Za-zsàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+$',
      STRING_NUMERIC:
        '^[A-Za-zsàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð0-9-() /.°]+$',
      ALPHANUMERIC: '^[a-zA-Z0-9-_]+$',
      TOKEN: /^[a-zA-Z0-9-]+$/,
      NUMERIC_DECIMAL: '^[0-9]+[.]{0,1}[0-9]*$',
      INTEGER_POSITIVE: /^[0-9]+$/,
      PHONE_NUMBER: /^[0-9]+-?[0-9]+$/,
      PASSWORD: /^(?=.*[A-Z])(?=.*[!@#$%^&*()])(?=.*[a-z])(?=.*\d)/,
      name: /^[0-9a-zA-Z\s/\\]+(\.[0-9]{1,2})?$/,
      price: /^[0-9a-zA-Z\s/\\]+(\.[0-9]{1,2})?$/,
    },
  },
  apis: {
    authApi: {
      URL: AUTH_URL,
      // URL: 'https://stage-administracion-api.policiamisiones.gob.ar',
      AUTHENTICATION_ENDPOINT: '/login',
      LOGOUT: '/logout',
      HEADERS: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    },
    generalApiUrlBase: API_URL,
    // generalApiUrlBase: 'https://stage-administracion-api.policiamisiones.gob.ar/api',
    default_pagination: {
      current_page: 1,
      from: 1,
      last_page: 1,
      path: API_URL + '/api/turns/list-assigned-turns',
      // path: 'https://stage-administracion-api.policiamisiones.gob.ar/api/turns/list-assigned-turns',
      per_page: 10,
      to: 4,
      total: 4,
    },
  },
}

export default DATA
