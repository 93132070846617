import * as ACTION_TYPES from './loginActionTypes'

export const loginReducerInitialState = {
  hasError: false,
  isFetching: false,
  loginParams: undefined,
  isLoggedIn: false,
  authentication: undefined,
  error: {},
  permissions: [],
  redirect: { name: 'Usuario', path: '/#/usuarios' },
}

export const loginReducer = (state = loginReducerInitialState, action) => {
  switch (action.type) {
    // Login actions
    case ACTION_TYPES.LOGIN_REQUEST: {
      const { params } = action.payload
      return {
        ...state,
        isFetching: true,
        hasError: false,
        isLoggedIn: false,
        error: undefined,
        loginParams: params,
      }
    }
    case ACTION_TYPES.LOGIN_REQUEST_SUCCESS: {
      const { data } = action.payload
      const role = data?.role[0]
      let permissionsAssigned = []
      if (role) {
        permissionsAssigned = role?.permissions
        permissionsAssigned =
          role.name === 'admin' || role.name === 'sistema'
            ? [...permissionsAssigned, role.name]
            : [...permissionsAssigned]
      }

      return {
        ...state,
        isFetching: false,
        hasError: false,
        error: undefined,
        password: undefined,
        isLoggedIn: true,
        authentication: action.payload,
        permissions: permissionsAssigned,
      }
    }
    case ACTION_TYPES.PLANT_USER_REQUEST_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        isFetching: false,
        hasError: false,
        error: undefined,
        password: undefined,
        authentication: {
          ...state?.authentication,
          data: { ...state?.authentication?.data, plant: data?.data },
        },
      }
    }
    case ACTION_TYPES.COMPANY_USER_REQUEST_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        isFetching: false,
        hasError: false,
        error: undefined,
        password: undefined,
        authentication: {
          ...state?.authentication,
          data: { ...state?.authentication?.data, company: data?.data },
        },
      }
    }
    case ACTION_TYPES.LOGIN_REQUEST_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        isFetching: false,
        hasError: true,
        isLoggedIn: false,
        // error
      }
    }
    case ACTION_TYPES.DELETE_PERMISSION_ITEM: {
      const { data } = action.payload
      const { permissions } = state
      const newPermissions = permissions.filter((item) => item !== data)

      return {
        ...state,
        permissions: newPermissions,
      }
    }
    case ACTION_TYPES.ADD_PERMISSION_ITEM: {
      const { data } = action.payload
      const { permissions } = state
      return {
        ...state,
        permissions: [...permissions, data],
      }
    }
    case ACTION_TYPES.CLEAR_LOGIN_DATA: {
      return {
        ...loginReducerInitialState,
      }
    }
    case ACTION_TYPES.CLEAR_LOGIN_PARAMS: {
      return {
        ...state,
        loginParams: loginReducerInitialState?.loginParams,
      }
    }
    // END Login actions
    case ACTION_TYPES.LOGOUT_REQUEST: {
      return {
        ...state,
        isFetching: false,
        hasError: false,
        isLoggedIn: false,
        error: undefined,
      }
    }
    case ACTION_TYPES.LOGOUT: {
      return {
        ...loginReducerInitialState,
      }
    }

    default: {
      return state
    }
  }
}

export default loginReducer

export const getLoginReducer = (state) => state.loginReducer
export const getIsLoggedIn = (state) => getLoginReducer(state).isLoggedIn
export const getIsFetching = (state) => getLoginReducer(state).isFetching
export const getLoginParams = (state) => getLoginReducer(state).loginParams

export const getUserAuthentication = (state) => getLoginReducer(state)
export const getUserLoggedData = (state) => getLoginReducer(state)?.authentication?.data

export const getExpiresDate = (state) => getUserAuthentication(state)?.expires_dates

export const getAccessToken = (state) => getUserAuthentication(state)?.authentication?.access_token
export const getProfileName = (state) => getUserAuthentication(state)?.profile_name
export const getAlias = (state) => getUserAuthentication(state)?.alias
export const getEmail = (state) => getUserAuthentication(state)?.email
export const getPlantId = (state) => getUserAuthentication(state)?.plant_id
export const getPermissionUserLogged = (state) => getLoginReducer(state)?.permissions

//logics
export const getCompanyUserLogged = (state) => {
  const userLogged = getUserLoggedData(state)
  let company = null
  if (userLogged?.company) {
    company = userLogged?.company
  } else if (userLogged?.plant) {
    if (userLogged?.plant?.company) {
      company = userLogged?.plant?.company
    }
  }
  return company
}
export const getRoleUserLogged = (state) => {
  const userLogged = getUserLoggedData(state)
  return userLogged?.role[0]?.name
}
