import * as ACTION_TYPES from './turnsActionTypes'
import config from 'src/configs/config'

export const turnsReducerInitialState = {
  listTurns: {
    data: undefined,
    isFetching: false,
    hasError: false,
    error: {},
  },
  listTurnsAssigneds: {
    data: undefined,
    meta: config.apis.default_pagination,
    isFetching: false,
    hasError: false,
    error: {},
  },
  findTurn: {
    data: undefined,
    isFetching: false,
    hasError: false,
    error: {},
  },
  fileTurn: {
    data: undefined,
    isFetching: false,
    hasError: false,
    error: {},
  },
  postTurn: {
    data: undefined,
    isFetching: false,
    hasError: false,
    errors: [],
  },
  deleteTurn: {
    data: undefined,
    isFetching: false,
    hasError: false,
    error: {},
  },
  enableDaysTurns: {
    data: undefined,
    isFetching: false,
    hasError: false,
    error: {},
  },
}

export const turnsReducer = (state = turnsReducerInitialState, action) => {
  switch (action.type) {
    // List turns filtered action handlers
    case ACTION_TYPES.TURNS_REQUEST: {
      return {
        ...state,
        listTurns: {
          ...state.listTurns,
          isFetching: true,
          hasError: false,
        },
      }
    }
    case ACTION_TYPES.TURNS_REQUEST_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        listTurns: {
          ...state.listTurns,
          isFetching: false,
          hasError: false,
          error: turnsReducerInitialState?.listTurns?.error,
          data,
        },
      }
    }
    case ACTION_TYPES.TURNS_REQUEST_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        listTurns: {
          ...state.listTurns,
          isFetching: false,
          hasError: true,
          data: turnsReducerInitialState?.listTurns?.data,
          // error
        },
      }
    }
    case ACTION_TYPES.CLEAR_TURNS_REQUEST: {
      return {
        ...state,
        listTurns: turnsReducerInitialState?.listTurns,
      }
    }
    // END List turns filtered action handlers
    // START TURNS ASSIGEND
    case ACTION_TYPES.TURNS_ASSIGNED_REQUEST: {
      return {
        ...state,
        listTurnsAssigneds: {
          ...state.listTurnsAssigneds,
          isFetching: true,
          hasError: false,
        },
      }
    }
    case ACTION_TYPES.TURNS_ASSIGNED_REQUEST_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        listTurnsAssigneds: {
          ...state.listTurnsAssigneds,
          isFetching: false,
          hasError: false,
          error: turnsReducerInitialState?.listTurnsAssigneds?.error,
          data: data.data,
          meta: { ...data?.meta, filter: data?.filter },
        },
      }
    }
    case ACTION_TYPES.TURNS_ASSIGNED_REQUEST_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        listTurnsAssigneds: {
          ...state.listTurnsAssigneds,
          isFetching: false,
          hasError: true,
          data: turnsReducerInitialState?.listTurnsAssigneds?.data,
          // error
        },
      }
    }
    case ACTION_TYPES.CLEAR_TURNS_ASSIGNED_REQUEST: {
      return {
        ...state,
        listTurnsAssigneds: turnsReducerInitialState?.listTurnsAssigneds,
      }
    }
    // END TURNS ASSIGEND

    //Start Find Turn filter action handles
    case ACTION_TYPES.FIND_TURN_REQUEST: {
      return {
        ...state,
        findTurn: {
          ...state.findTurn,
          isFetching: true,
          hasError: false,
        },
      }
    }
    case ACTION_TYPES.FIND_TURN_REQUEST_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        findTurn: {
          ...state.findTurn,
          isFetching: false,
          hasError: false,
          error: turnsReducerInitialState?.findTurn?.error,
          data,
        },
      }
    }
    case ACTION_TYPES.FIND_TURN_REQUEST_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        findTurn: {
          ...state.findTurn,
          isFetching: false,
          hasError: true,
          data: turnsReducerInitialState?.findTurn?.data,
          // error
        },
      }
    }

    //END FIND
    //Start FILE Turn filter action handles
    case ACTION_TYPES.FILE_TURN_REQUEST: {
      return {
        ...state,
        fileTurn: {
          ...state.fileTurn,
          isFetching: true,
          hasError: false,
        },
      }
    }
    case ACTION_TYPES.FILE_TURN_REQUEST_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        fileTurn: {
          ...state.fileTurn,
          isFetching: false,
          hasError: false,
          error: turnsReducerInitialState?.fileTurn?.error,
          data,
        },
      }
    }
    case ACTION_TYPES.FILE_TURN_REQUEST_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        fileTurn: {
          ...state.fileTurn,
          isFetching: false,
          hasError: true,
          data: turnsReducerInitialState?.fileTurn?.data,
          // error
        },
      }
    }

    //END FILE
    //Start Find Turn filter action handles
    case ACTION_TYPES.POST_TURNS_REQUEST: {
      return {
        ...state,
        postTurn: {
          ...state.postTurn,
          isFetching: true,
          hasError: false,
        },
      }
    }
    case ACTION_TYPES.POST_TURNS_REQUEST_SUCCESS: {
      const { data,errors } = action.payload
      return {
        ...state,
        postTurn: {
          ...state.postTurn,
          isFetching: false,
          hasError: false,
          errors: errors,
          data,
        },
      }
    }
    case ACTION_TYPES.POST_TURNS_REQUEST_FAILURE: {
      const { errors } = action.payload
      return {
        ...state,
        postTurn: {
          ...state.postTurn,
          isFetching: false,
          hasError: true,
          data: turnsReducerInitialState?.postTurn?.data,
          errors
        },
      }
    }

    case ACTION_TYPES.DELETE_TURNS_REQUEST: {
      return {
        ...state,
        deleteTurn: {
          ...state.deleteTurn,
          isFetching: true,
          hasError: false,
        },
      }
    }
    case ACTION_TYPES.DELETE_TURNS_REQUEST_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        deleteTurn: {
          ...state.deleteTurn,
          isFetching: false,
          hasError: false,
          error: turnsReducerInitialState.deleteTurn.error,
          data,
        },
      }
    }
    case ACTION_TYPES.DELETE_TURNS_REQUEST_FAILURE: {
      const { errors } = action.payload
      return {
        ...state,
        deleteTurn: {
          ...state.deleteTurn,
          isFetching: false,
          hasError: true,
          data:turnsReducerInitialState?.deleteTurn?.data,
          errors: errors,
        },
      }
    }

    //END FIND TURNS_ENABLE_DAYS_REQUEST
    case ACTION_TYPES.TURNS_ENABLE_DAYS_REQUEST: {
      return {
        ...state,
        enableDaysTurns: {
          ...state.enableDaysTurns,
          isFetching: true,
          hasError: false,
          error: turnsReducerInitialState?.enableDaysTurns?.error,
        },
      }
    }
    case ACTION_TYPES.TURNS_ENABLE_DAYS_REQUEST_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        enableDaysTurns: {
          ...state.enableDaysTurns,
          isFetching: false,
          hasError: false,
          error: turnsReducerInitialState?.enableDaysTurns?.error,
          data,
        },
      }
    }
    case ACTION_TYPES.TURNS_ENABLE_DAYS_REQUEST_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        enableDaysTurns: {
          ...state.enableDaysTurns,
          isFetching: false,
          hasError: true,
          data: turnsReducerInitialState?.enableDaysTurns?.data,
          // error
        },
      }
    }
    case ACTION_TYPES.TURNS_ENABLE_DAYS_SET: {
      const { data } = action.payload
      return {
        ...state,
        enableDaysTurns: {
          ...state.enableDaysTurns,
          isFetching: false,
          hasError: false,
          error: turnsReducerInitialState?.enableDaysTurns?.error,
          data,
        },
      }
    }
    case ACTION_TYPES.TURNS_ENABLE_DAYS_CLEAR_REQUEST: {
      return {
        ...state,
        enableDaysTurns: turnsReducerInitialState?.enableDaysTurns,
      }
    }

    // END Find turns filtered action handlers
    default: {
      return state
    }
  }
}

export default turnsReducer

export const getTurnsReducer = (state) => state.turnsReducer

// Getters list candidates filtered
export const getListTurns = (state) => getTurnsReducer(state)?.listTurns
export const getListTurnsData = (state) => getListTurns(state)?.data
export const getListTurnsIsFetching = (state) => getListTurns(state)?.isFetching
export const getListTurnsHasError = (state) => getListTurns(state)?.hasError
export const getListTurnsError = (state) => getListTurns(state)?.error

// Getters list candidates ASSIGNEDS
export const getListTurnsAssigneds = (state) => getTurnsReducer(state)?.listTurnsAssigneds
export const getListTurnsAssignedsData = (state) => getListTurnsAssigneds(state)?.data
export const getListTurnsAssignedsMetaData = (state) => getListTurnsAssigneds(state)?.meta
export const getListTurnsAssignedsIsFetching = (state) => getListTurnsAssigneds(state)?.isFetching
export const getListTurnsAssignedsHasError = (state) => getListTurnsAssigneds(state)?.hasError
export const getListTurnsAssignedsError = (state) => getListTurnsAssigneds(state)?.error

//Getters for find  element
export const getFindTurn = (state) => getTurnsReducer(state)?.findTurn
export const getFindTurnData = (state) => getFindTurn(state)?.data
export const getFindTurnIsFetching = (state) => getFindTurn(state)?.isFetching
export const getFindTurnHasError = (state) => getFindTurn(state)?.hasError
export const getFindTurnError = (state) => getFindTurn(state)?.error

//Getters for file  element
export const getFindFileTurn = (state) => getTurnsReducer(state)?.fileTurn
export const getFindFileTurnData = (state) => getFindFileTurn(state)?.data
export const getFindFileTurnIsFetching = (state) => getFindFileTurn(state)?.isFetching
export const getFindFileTurnHasError = (state) => getFindFileTurn(state)?.hasError
export const getFindFileTurnError = (state) => getFindFileTurn(state)?.error

//Getters for find  element
export const getDeleteTurn = (state) => getTurnsReducer(state)?.deleteTurn
export const getDeleteTurnData = (state) => getDeleteTurn(state)?.data
export const getDeleteTurnIsFetching = (state) => getDeleteTurn(state)?.isFetching
export const getDeleteTurnHasError = (state) => getDeleteTurn(state)?.hasError
export const getDeleteTurnError = (state) => getDeleteTurn(state)?.error

export const getPostTurn = (state) => getTurnsReducer(state)?.postTurn
export const getPostTurnData = (state) => getPostTurn(state)?.data
export const getPostTurnIsFetching = (state) => getPostTurn(state)?.isFetching
export const getPostTurnHasError = (state) => getPostTurn(state)?.hasError
export const getPostTurnError = (state) => getPostTurn(state)?.error

export const getEnableDaysTurns = (state) => getTurnsReducer(state)?.enableDaysTurns
export const getEnableDaysTurnsData = (state) => getEnableDaysTurns(state)?.data
export const getEnableDaysTurnsDataDate = (state) =>
  getEnableDaysTurns(state)?.data.map((x) => x.date)
export const getEnableDaysTurnsIsFetching = (state) => getEnableDaysTurns(state)?.isFetching
export const getEnableDaysTurnsTurnsHasError = (state) => getEnableDaysTurns(state)?.hasError
export const getEnableDaysTurnsTurnsError = (state) => getEnableDaysTurns(state)?.error
