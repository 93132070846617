import * as ACTION_TYPES from './companiesActionTypes'

export const companiesReducerInitialState = {
  listCompanies: {
    data: [],
    meta: { current_page: 0, last_page: 1, from: 1 },
    isFetching: false,
    hasError: false,
    error: {},
  },
  findCompany: {
    data: undefined,
    isFetching: false,
    hasError: false,
    error: {},
  },
  createCompany: {
    data: undefined,
    isFetching: false,
    hasError: false,
    error: {},
  },
}

export const companiesReducer = (state = companiesReducerInitialState, action) => {
  switch (action.type) {
    // List companies filtered action handlers
    case ACTION_TYPES.COMPANIES_REQUEST: {
      return {
        ...state,
        listCompanies: {
          ...state.listCompanies,
          isFetching: true,
          hasError: false,
        },
      }
    }
    case ACTION_TYPES.COMPANIES_REQUEST_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        listCompanies: {
          ...state?.listCompanies,
          isFetching: false,
          hasError: false,
          error: companiesReducerInitialState?.listCompanies?.error,
          data: data?.data ?? [],
          meta: data?.meta ?? companiesReducerInitialState?.listCompanies?.meta,
        },
      }
    }
    case ACTION_TYPES.COMPANIES_REQUEST_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        listCompanies: {
          ...state.listCompanies,
          isFetching: false,
          hasError: true,
          data: companiesReducerInitialState?.listCompanies?.data,
          // error,
        },
      }
    }
    case ACTION_TYPES.CLEAR_COMPANIES_REQUEST: {
      return {
        ...state,
        listCompanies: companiesReducerInitialState?.listCompanies,
      }
    }
    // END List companies filtered action handlers

    //Start Find Company filter action handles
    case ACTION_TYPES.FIND_COMPANY_REQUEST: {
      return {
        ...state,
        findCompany: {
          ...state.findCompany,
          isFetching: true,
          hasError: false,
        },
      }
    }
    case ACTION_TYPES.FIND_COMPANY_REQUEST_SUCCESS: {
      const { data } = action.payload

      return {
        ...state,
        findCompany: {
          ...state.findCompany,
          isFetching: false,
          hasError: false,
          error: companiesReducerInitialState?.findCompany?.error,
          data,
        },
      }
    }
    case ACTION_TYPES.FIND_COMPANY_REQUEST_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        findCompany: {
          ...state.findCompany,
          isFetching: false,
          hasError: true,
          data: companiesReducerInitialState?.findCompany?.data,
          // error,
        },
      }
    }
    case ACTION_TYPES.CLEAR_FIND_COMPANIES_REQUEST: {
      return {
        ...state,
        findCompany: companiesReducerInitialState?.findCompany,
      }
    }

    // END Find companies filtered action handlers

    //Start create Company filter action handles
    case ACTION_TYPES.CREATE_COMPANY_REQUEST: {
      return {
        ...state,
        createCompany: {
          ...state.createCompany,
          isFetching: true,
          hasError: false,
        },
      }
    }
    case ACTION_TYPES.CREATE_COMPANY_REQUEST_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        createCompany: {
          ...state.createCompany,
          isFetching: false,
          hasError: false,
          error: companiesReducerInitialState?.createCompany?.error,
          data,
        },
      }
    }
    case ACTION_TYPES.CREATE_COMPANY_REQUEST_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        createCompany: {
          ...state.createCompany,
          isFetching: false,
          hasError: true,
          data: companiesReducerInitialState?.createCompany?.data,
          // error,
        },
      }
    }

    // END Find companies filtered action handlers
    default: {
      return state
    }
  }
}

export default companiesReducer

export const getCompaniesReducer = (state) => state.companiesReducer

// Getters list candidates filtered
export const getListCompanies = (state) => getCompaniesReducer(state)?.listCompanies
export const getListCompaniesData = (state) => getListCompanies(state)?.data
export const getListCompaniesMetaData = (state) => getListCompanies(state)?.meta
export const getListCompaniesIsFetching = (state) => getListCompanies(state)?.isFetching
export const getListCompaniesHasError = (state) => getListCompanies(state)?.hasError
export const getListCompaniesError = (state) => getListCompanies(state)?.error

//Getters for find  element
export const getFindCompany = (state) => getCompaniesReducer(state)?.findCompany
export const getFindCompanyData = (state) => getFindCompany(state)?.data
export const getFindCompanyIsFetching = (state) => getFindCompany(state)?.isFetching
export const getFindCompanyHasError = (state) => getFindCompany(state)?.hasError
export const getFindCompanyError = (state) => getFindCompany(state)?.error

//Getters for find  element
export const getCreateCompany = (state) => getCompaniesReducer(state)?.createCompany
export const getCreateCompanyData = (state) => getCreateCompany(state)?.data
export const getCreateCompanyIsFetching = (state) => getCreateCompany(state)?.isFetching
export const getCreateCompanyHasError = (state) => getCreateCompany(state)?.hasError
export const getCreateCompanyError = (state) => getCreateCompany(state)?.error
