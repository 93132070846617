
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS';
export const LOGIN_REQUEST_FAILURE = 'LOGIN_REQUEST_FAILURE';
export const LOGIN_REDIRECT = 'LOGIN_REDIRECT';
export const CLEAR_LOGIN_DATA = 'CLEAR_LOGIN_DATA';
export const CLEAR_LOGIN_PARAMS = 'CLEAR_LOGIN_PARAMS';
export const DELETE_PERMISSION_ITEM = 'DELETE_PERMISSION_ITEM';
export const ADD_PERMISSION_ITEM = 'ADD_PERMISSION_ITEM';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_REQUEST_SUCCESS = 'RESET_PASSWORD_REQUEST_SUCCESS';
export const RESET_PASSWORD_REQUEST_FAILURE = 'RESET_PASSWORD_REQUEST_FAILURE';
export const CLEAR_RESET_PASSWORD_DATA = 'CLEAR_RESET_PASSWORD_DATA';

export const FORGOT_PASSWORD_SEND_EMAIL_REQUEST = 'FORGOT_PASSWORD_SEND_EMAIL_REQUEST';
export const FORGOT_PASSWORD_SEND_EMAIL_REQUEST_RESOLVE = 'FORGOT_PASSWORD_SEND_EMAIL_REQUEST_RESOLVE';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';

export const PLANT_USER_REQUEST_SUCCESS = 'PLANT_USER_REQUEST_SUCCESS';
export const COMPANY_USER_REQUEST_SUCCESS = 'COMPANY_USER_REQUEST_SUCCESS';
