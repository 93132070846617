import * as ACTION_TYPES from './entitiesActionTypes'

export const entitiesReducerInitialState = {
  listEntities: {
    data: [],
    isFetching: false,
    hasError: false,
    error: {},
  },
}

export const entitiesReducer = (state = entitiesReducerInitialState, action) => {
  switch (action.type) {
    // List plants filtered action handlers
    case ACTION_TYPES.ENTITIES_REQUEST: {
      return {
        ...state,
        listEntities: {
          ...state.listEntities,
          isFetching: true,
          hasError: false,
        },
      }
    }
    case ACTION_TYPES.ENTITIES_REQUEST_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        listEntities: {
          ...state.listEntities,
          isFetching: false,
          hasError: false,
          error: entitiesReducerInitialState?.error,
          data,
        },
      }
    }
    case ACTION_TYPES.ENTITIES_REQUEST_FAILURE: {
      return {
        ...state,
        listEntities: {
          ...state.listEntities,
          isFetching: false,
          hasError: true,
        },
      }
    }
    case ACTION_TYPES.CLEAR_ENTITIES_REQUEST: {
      return {
        ...state,
        listEntities: entitiesReducerInitialState?.listEntities,
      }
    }
    default: {
      return state
    }
  }
}

export default entitiesReducer

export const getEntitiesReducer = (state) => state.entitiesReducer

export const getListEntities = (state) => getEntitiesReducer(state)?.listEntities
export const getListEntitiesData = (state) => getListEntities(state)?.data
export const getListEntitiesIsFetching = (state) => getListEntities(state)?.isFetching
