import * as ACTION_TYPES from './credentialsActionTypes'

export const credentialsReducerInitialState = {
  addCredentials: {
    data: undefined,
    isFetching: false,
    hasError: false,
    error: {},
  },
}

export const credentialsReducer = (state = credentialsReducerInitialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.CREATE_CREDENTIALS_REQUEST: {
      return {
        ...state,
        addCredentials: {
          ...state.addCredentials,
          isFetching: true,
          hasError: false,
        },
      }
    }
    case ACTION_TYPES.CREATE_CREDENTIALS_REQUEST_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        addCredentials: {
          ...state.addCredentials,
          isFetching: false,
          hasError: false,
          error: credentialsReducerInitialState?.addCredentials?.error,
          data,
        },
      }
    }
    case ACTION_TYPES.CREATE_CREDENTIALS_REQUEST_FAILURE: {
      return {
        ...state,
        addCredentials: {
          ...state.addCredentials,
          isFetching: false,
          hasError: true,
          data: credentialsReducerInitialState?.addCredentials?.data,
        },
      }
    }
    case ACTION_TYPES.CLEAR_CREDENTIALS_REQUEST: {
      return {
        ...state,
        addCredentials: credentialsReducerInitialState?.addCredentials,
      }
    }
    default: {
      return state
    }
  }
}

export default credentialsReducer

export const getCredentialsReducer = (state) => state.credentialsReducer

export const getAddCredentials = (state) => getCredentialsReducer(state)?.addCredentials
export const postCredentialsData = (state) => getAddCredentials(state)?.data
export const postCredentialsDataIsFetching = (state) => getAddCredentials(state)?.isFetching
