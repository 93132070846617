import { combineReducers } from 'redux'
// import { connectRouter } from 'connected-react-router'
/* import { reducer as notifications } from 'react-notification-system-redux' */

import { loginReducer, loginReducerInitialState } from 'src/redux/login/loginReducer'
import {
  globalDataReducer,
  globalDataReducerInitialState,
} from 'src/redux/globalData/globalDataReducer'
import { usersReducer, usersReducerInitialState } from 'src/redux/users/usersReducer'
import { coreApiReducer, coreApiReducerInitialState } from 'src/redux/coreApi/coreApiReducer'
import { plantsReducer, plantsReducerInitialState } from 'src/redux/plants/plantsReducer'
import { servicesReducer, servicesReducerInitialState } from 'src/redux/services/servicesReducer/'
import {
  servicesPricesReducer,
  servicesPricesReducerInitialState,
} from 'src/redux/servicesPrices/servicesPricesReducer'
import { rolesReducer, rolesReducerInitialState } from 'src/redux/roles/rolesReducer'
import { turnsReducer, turnsReducerInitialState } from 'src/redux/turns/turnsReducer'
import { paymentReducer, paymentReducerInitialState } from 'src/redux/paymentData/paymentReducer'
import {
  statisticsReducer,
  statisticsReducerInitialState,
} from 'src/redux/statistics/statisticsReducer'
import {
  localitiesReducer,
  localitiesReducerInitialState,
} from 'src/redux/localities/localitiesReducer'
import {
  companiesReducer,
  companiesReducerInitialState,
} from 'src/redux/companies/companiesReducer'
import { entitiesReducer, entitiesReducerInitialState } from 'src/redux/entities/entitiesReducer'
import {
  credentialsReducer,
  credentialsReducerInitialState,
} from 'src/redux/credentials/credentialsReducer'

import { LOGOUT } from 'src/redux/login/loginActionTypes'

const createRootReducer = (history) => {
  const appReducer = combineReducers({
    loginReducer,
    globalDataReducer,
    usersReducer,
    coreApiReducer,
    plantsReducer,
    servicesReducer,
    servicesPricesReducer,
    rolesReducer,
    turnsReducer,
    paymentReducer,
    statisticsReducer,
    localitiesReducer,
    companiesReducer,
    entitiesReducer,
    credentialsReducer,
  })

  return (state, action) => {
    if (action.type === LOGOUT) {
      state.globalDataReducer = globalDataReducerInitialState
      state.usersReducer = usersReducerInitialState
      state.coreApiReducer = coreApiReducerInitialState
      state.plantsReducer = plantsReducerInitialState
      state.servicesReducer = servicesReducerInitialState
      state.rolesReducerInitialState = rolesReducerInitialState
      state.turnsReducer = turnsReducerInitialState
      state.loginReducer = loginReducerInitialState
      state.paymentReducer = paymentReducerInitialState
      state.localitiesReducer = localitiesReducerInitialState
      state.companiesReducer = companiesReducerInitialState
      state.entitiesReducer = entitiesReducerInitialState
      state.credentialsReducer = credentialsReducerInitialState
    }
    return appReducer(state, action)
  }
}

export default createRootReducer
