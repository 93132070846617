
export const TURNS_REQUEST = 'TURNS_REQUEST'; //para saber si esta buscando (fetching)
export const TURNS_REQUEST_SUCCESS = 'TURNS_REQUEST_SUCCESS'; //si los datos llegan correcto, o sea en un 200 201 o 204
export const TURNS_REQUEST_FAILURE = 'TURNS_REQUEST_FAILURE'; //si falla el request y devuelve error, ej: 400, 404, 500
export const CLEAR_TURNS_REQUEST = 'CLEAR_TURNS_REQUEST'; //con esto limpiamos la data del estado global

//only an element
export const FIND_TURN_REQUEST = 'FIND_TURN_REQUEST';
export const FIND_TURN_REQUEST_SUCCESS = 'FIND_TURN_REQUEST_SUCCESS';
export const FIND_TURN_REQUEST_FAILURE = 'FIND_TURN_REQUEST_FAILURE';

export const FILE_TURN_REQUEST = 'FILE_TURN_REQUEST';
export const FILE_TURN_REQUEST_SUCCESS = 'FILE_TURN_REQUEST_SUCCESS';
export const FILE_TURN_REQUEST_FAILURE = 'FILE_TURN_REQUEST_FAILURE';

export const TURNS_ENABLE_DAYS_REQUEST = 'TURNS_ENABLE_DAYS_REQUEST';
export const TURNS_ENABLE_DAYS_REQUEST_SUCCESS = 'TURNS_ENABLE_DAYS_REQUEST_SUCCESS';
export const TURNS_ENABLE_DAYS_REQUEST_FAILURE = 'TURNS_ENABLE_DAYS_REQUEST_FAILURE';
export const TURNS_ENABLE_DAYS_CLEAR_REQUEST = 'TURNS_ENABLE_DAYS_CLEAR_REQUEST';
export const TURNS_ENABLE_DAYS_SET = 'TURNS_ENABLE_DAYS_SET';

export const POST_TURNS_REQUEST = 'POST_TURNS_REQUEST';
export const POST_TURNS_REQUEST_SUCCESS = 'POST_TURNS_REQUESTT_SUCCESS';
export const POST_TURNS_REQUEST_FAILURE = 'POST_TURNS_REQUEST_FAILURE';

export const DELETE_TURNS_REQUEST = 'DELETE_TURNS_REQUEST';
export const DELETE_TURNS_REQUEST_SUCCESS = 'DELETE_TURNS_REQUESTT_SUCCESS';
export const DELETE_TURNS_REQUEST_FAILURE = 'DELETE_TURNS_REQUEST_FAILURE';


export const TURNS_ASSIGNED_REQUEST = 'TURNS_ASSIGNED_REQUEST'; //para saber si esta buscando (fetching)
export const TURNS_ASSIGNED_REQUEST_SUCCESS = 'TURNS_ASSIGNED_REQUEST_SUCCESS'; //si los datos llegan correcto, o sea en un 200 201 o 204
export const TURNS_ASSIGNED_REQUEST_FAILURE = 'TURNS_ASSIGNED_REQUEST_FAILURE'; //si falla el request y devuelve error, ej: 400, 404, 500
export const CLEAR_TURNS_ASSIGNED_REQUEST = 'CLEAR_TURNS_ASSIGNED_REQUEST'; //con esto limpiamos la data del estado global
