import * as ACTION_TYPES from './localitiesActionTypes';

export const localitiesReducerInitialState = {
	getLocalities: {
    data:[],
		meta:{current_page:0,last_page:1},
		isFetching: false,
		hasError: false,
		error: {}
	}
};

export const localitiesReducer = (state = localitiesReducerInitialState, action) => {
	switch (action.type) {

		// List plants filtered action handlers
		case ACTION_TYPES.LOCALITIES_REQUEST: {
			return {
				...state,
				getLocalities: {
					...state.getLocalities,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.LOCALITIES_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				getLocalities: {
					...state.getLocalities,
					isFetching: false,
					hasError: false,
					error: localitiesReducerInitialState?.getLocalities?.error,
					data: data?.data,
          meta:data?.meta ?? localitiesReducerInitialState?.getLocalities?.meta
				}
			};
		}


		case ACTION_TYPES.LOCALITIES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				getLocalities: {
					...state.getLocalities,
					isFetching: false,
					hasError: true,
					data: localitiesReducerInitialState?.getLocalities?.data,
					// error
				}
			};
		}
		case ACTION_TYPES.CLEAR_LOCALITIES_REQUEST: {
			return {
				...state,
				getLocalities: localitiesReducerInitialState?.getLocalities
			};
		}
		// END List plants filtered action handlers



		default: {
			return state;
		}
	}
};

export default localitiesReducer;

export const getLocalitiesReducer = state => state.localitiesReducer;

// Getters list candidates filtered
export const getLocalities = state => getLocalitiesReducer(state)?.getLocalities;
export const getLocalitiesData = state => getLocalities(state)?.data;
export const getLocalitiesMetaData = state => getLocalities(state)?.meta;
export const getLocalitiesIsFetching = state => getLocalities(state)?.isFetching;
export const getLocalitiesHasError = state => getLocalities(state)?.hasError;
export const getLocalitiesError = state => getLocalities(state)?.error;
