
export const COMPANIES_REQUEST = 'COMPANIES_REQUEST'; //para saber si esta buscando (fetching)
export const COMPANIES_REQUEST_SUCCESS = 'COMPANIES_REQUEST_SUCCESS'; //si los datos llegan correcto, o sea en un 200 201 o 204
export const COMPANIES_REQUEST_FAILURE = 'COMPANIES_REQUEST_FAILURE'; //si falla el request y devuelve error, ej: 400, 404, 500
export const CLEAR_COMPANIES_REQUEST = 'CLEAR_COMPANIES_REQUEST'; //con esto limpiamos la data del estado global

//only an element
export const FIND_COMPANY_REQUEST = 'FIND_COMPANY_REQUEST';
export const FIND_COMPANY_REQUEST_SUCCESS = 'FIND_COMPANY_REQUEST_SUCCESS';
export const FIND_COMPANY_REQUEST_FAILURE = 'FIND_COMPANY_REQUEST_FAILURE';
export const CLEAR_FIND_COMPANIES_REQUEST = 'CLEAR_FIND_COMPANIES_REQUEST'; //con esto limpiamos la data del estado global

//only an element
export const UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST';
export const UPDATE_COMPANY_REQUEST_SUCCESS = 'UPDATE_COMPANY_REQUEST_SUCCESS';
export const UPDATE_COMPANY_REQUEST_FAILURE = 'UPDATE_COMPANY_REQUEST_FAILURE';
export const CLEAR_UPDATE_COMPANIES_REQUEST = 'CLEAR_UPDATE_COMPANIES_REQUEST'; //con esto limpiamos la data del estado global

//only an element
export const CREATE_COMPANY_REQUEST = 'CREATE_COMPANY_REQUEST';
export const CREATE_COMPANY_REQUEST_SUCCESS = 'CREATE_COMPANY_REQUEST_SUCCESS';
export const CREATE_COMPANY_REQUEST_FAILURE = 'CREATE_COMPANY_REQUEST_FAILURE';
